import React, { Component } from 'react';
import connectLogo from '../img/connect-with-ik.svg';
import SuccessView from './Success';

enum SendStatus {
  Unsent = 0,
  Sending = 1,
  Success = 2,
  Failed = 3,
}

interface Props {
  name: string;
  hideButton: boolean;
}

interface State {
  name: string;
  buttonText: string;
  dockLeft: boolean;
  message: string;
  minimized: boolean;
  phone: string;
  phoneInputDirty: boolean;
  sendStatus: SendStatus;
  bottomOffset: number;
  locations: any;
  selectedShopToken: any;
  hideButton: boolean;
}

class App extends Component<Props, State> {
  private widgetFormRef = React.createRef();
  private widgetButtonRef = React.createRef();

  constructor(props: Props) {
    super(props);
    window.addEventListener('message', this.receiveMessage, false);

    const hideButton = props.hideButton !== undefined ? props.hideButton : true

    this.state = {
      buttonText: 'Send Text',
      dockLeft: false,
      message: '',
      minimized: true,
      name: '',
      phone: '',
      phoneInputDirty: false,
      sendStatus: SendStatus.Unsent,
      bottomOffset: 0,
      locations: null,
      selectedShopToken: null,
      hideButton
    };
  }

  componentDidUpdate = () => {
    try {
      const phoneInputSelector = document.getElementsByClassName(
        'phoneInput form-control'
      );
      if (phoneInputSelector.length) {
        const phoneInput: any = phoneInputSelector[0];
        phoneInput.id = 'phone';
        phoneInput.setAttribute('aria-label', 'Phone Input');
      }
    } catch (error) {
      console.error(error);
    }
  };

  receiveMessage = (event: MessageEvent) => {
    if (!!event && !event.data) return;


    if (event.data.type === 'INIT_IFRAME') {
      const {
        shopToken,
        customBgColor,
        formOpened,
        dockLeft,
        bottomOffset,
        locations,
        hideButton
      } = event.data;

      if (hideButton === null) {
        this.setState({hideButton: true})
      } else {
        this.setState({ hideButton })
      }
      if (!locations || locations.length === 0) {
        this.setState({ selectedShopToken: shopToken });
      } else if (locations && locations.length === 1) {
        this.setState({ selectedShopToken: locations[0].shopId || locations[0].shop_id });
      }
      if (customBgColor) {
        document.documentElement.style.setProperty(
          '--ik-widget-bg-color',
          customBgColor
        );
      }
      if (dockLeft) {
        this.setState({ dockLeft });
      }
      if (formOpened) {
        this.toggleWidget();
      }
      if (bottomOffset) {
        this.setState({ bottomOffset });
      }
      if (locations) {
        this.setState({ locations });
      }
    } else if (event.data.type === 'OPEN_WIDGET') {
      if (this.state.minimized) {
        this.toggleWidget();
      }

      const updatedState = {
        message: event.data.message_body,
        mockApiCall: event.data.mock_api_call
      }
      if (event.data.location_id) {
        updatedState.selectedShopToken = event.data.location_id
      }

      this.setState(updatedState);
    } else if (event.data.type === 'CLOSE_WIDGET') {
      if (!this.state.minimized) {
        this.toggleWidget();
      }
    }
  };

  toggleWidget = () => {
    this.setState((prevState) => {
      const minimized = !prevState.minimized;
      window.parent.postMessage(
        {
          type: 'MINIMIZE_WIDGET',
          value: minimized,
        },
        '*'
      );
      this.widgetButtonRef.current.classList.toggle('widget-min-hide');
      this.widgetFormRef.current.classList.toggle('widget-hide');
      return { minimized };
    });
  };

  fetchParentOrigin = () => {
    return new Promise((resolve) => {
      window.parent.postMessage(
        {
          type: 'CURRENT_ORIGIN',
        },
        '*'
      );
      window.onmessage = function (event: MessageEvent) {
        if (event.data.type === 'CURRENT_ORIGIN') {
          resolve(event.data.origin);
        }
      };
    });
  };

  handleBlur = (event) => {
    this.setState({ phoneInputDirty: true });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  validateForm = () => {};

  sendMessage = (data: any) => {
    const onSuccess = () => {
      this.setState({
        sendStatus: SendStatus.Success,
        buttonText: 'Text Sent!',
        message: '',
      });
      try {
        window.parent.postMessage(
          {
            event_name: 'send_message',
            event_source: 'ikeono',
            customer_mobile_number: data.customer_mobile_number,
            customer_name: data.customer_name,
            origin: data.origin,
            body: data.body,
            location_id: this.state.selectedShopToken
          },
          '*'
        );
      } catch (e) {
        console.log(e);
      }
    }

    if (this.state.mockApiCall) {
      console.warn(`Ikeono Widget: sending message with mock API call`)
      onSuccess()
      return
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    
    fetch(`${process.env.API_ENDPOINT}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          response
            .json()
            .then((data) => {
              const message = data.detail
                ? data.detail
                : 'Failed to send, please try again.';
              this.setState({
                sendStatus: SendStatus.Failed,
                buttonText: message,
              });
            })
            .catch((error) => {
              console.error(error);
              this.setState({
                sendStatus: SendStatus.Failed,
                buttonText: 'Failed to send, please try again.',
              });
            });
        } else {
          onSuccess()
        }
      })
      .catch((error) => console.error(error));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      sendStatus: SendStatus.Sending,
      buttonText: 'Sending Text...',
    });

    this.fetchParentOrigin().then((parentOrigin) => {
      const { name, phone, message } = this.state;
      const data = {
        customer_name: name,
        customer_mobile_number: phone.replace(/[^\+0-9]/g, ''),
        body: message,
        token: this.state.selectedShopToken || process.env.TEST_TOKEN,
        origin: parentOrigin || document.referrer,
      };

      this.sendMessage(data);
    });
  };

  render = () => {
    const widgetHeight =
      this.state.locations && this.state.locations.length > 1
        ? '470px'
        : '420px';
      
    return (
      <>
        <div
          style={{display: this.state.hideButton ? "none" : "flex"}}
          ref={this.widgetButtonRef}
          className={`widget-min ${
            this.state.dockLeft ? 'widget-min-dock-left' : ''
          }`}
          onClick={this.toggleWidget}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chat-left-text-fill"
            viewBox="0 0 16 16"
          >
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
          </svg>
          Text us
        </div>
        <div
          ref={this.widgetFormRef}
          className="widget widget-hide"
          style={{ height: widgetHeight }}
        >
          <div className="widget__header">
            Text us!
            <div
              data-size="normal"
              className="widget__header__close"
              onClick={this.toggleWidget}
            >
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  fill="#fff"
                ></path>
              </svg>
            </div>
          </div>
          {this.state.sendStatus === SendStatus.Success ? (
            <SuccessView
              phone={this.state.phone}
              reset={() =>
                this.setState({
                  sendStatus: SendStatus.Unsent,
                  buttonText: 'Send Text',
                })
              }
            />
          ) : (
            <form onSubmit={this.handleSubmit}>
              <label htmlFor="name">Name:</label>
              <input
                id="name"
                aria-label="Your first and last name"
                required
                type="text"
                name="name"
                placeholder="First and Last Name"
                value={this.state.name}
                onChange={this.handleInputChange}
                style={{ fontFamily: 'inherit' }}
              />
              <label htmlFor="phone">Mobile number:</label>
              <input
                id="phone"
                aria-label="Mobile number"
                required
                type="text"
                name="phone"
                placeholder="Mobile number"
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
                maxLength={30}
                minLength={9}
                style={{ fontFamily: 'inherit' }}
                ref={(node) => {
                  this.phoneInput = node;
                }}
              />
              <textarea
                aria-label="How can we help you?"
                required
                rows={4}
                maxLength={160}
                placeholder="How can we help you?"
                name="message"
                style={{ fontFamily: 'inherit' }}
                value={this.state.message}
                onChange={this.handleInputChange}
              ></textarea>
              {this.state.locations?.length > 1 && (
                <>
                  <label htmlFor="location">Location:</label>
                  <select
                    style={{ width: '100%' }}
                    required
                    id="location"
                    value={this.state.selectedShopToken}
                    name="location"
                    onChange={(e: any) => {
                      this.setState({
                        selectedShopToken: e.target.value,
                      });
                    }}
                  >
                    <option key="null"></option>
                    {this.state.locations.map((location: any) => (
                      <option key={location.shopId || location.shop_id} value={location.shopId || location.shop_id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
              <span
                style={{
                  fontSize: '10px',
                  marginBottom: '35px',
                  color: '#333',
                }}
              >
                By texting us, you agree to receive text messages at the number
                provided. Standard message/data rates apply.
                <a
                  tabIndex={-1}
                  target="_blank"
                  href="https://ikeono.com/terms-of-use"
                >
                  Use is subject to terms.
                </a>
              </span>
              <div className="widget__submit">
                <button className="widget__submit__button" type="submit">
                  {this.state.buttonText}
                  {this.state.sendStatus === SendStatus.Sending && (
                    <div className="widget__spinner"></div>
                  )}
                </button>
              </div>
            </form>
          )}

          <div className="widget__connect">
            <img
              style={{ opacity: '50%' }}
              src={connectLogo}
              alt="Powered by Ikeono"
            />
          </div>
        </div>
      </>
    );
  };
}

export default App;
