import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './App';
import './styles.scss';


let hideButton = undefined;
if (process.env.NODE_ENV === 'dev') {
    hideButton = false
}


const mountNode = document.getElementById('app');
ReactDOM.render(<App hideButton={hideButton}/>, mountNode);
