import React from 'react';

const SuccessView = (props) => {
  return (
    <div className="success">
      <div>
        <p>Your text message has been sent!</p>{' '}
        <p>
          We will text you at
          <br />
          {props.phone} soon.
        </p>
      </div>
      <div className="widget__submit">
        <button className="widget__submit__button" onClick={props.reset}>
          Send Another Text
        </button>
      </div>
    </div>
  );
};

export default SuccessView;
